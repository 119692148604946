<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected container"
      :class="{ open: open }"
      @click="open = !open"
    >
      <slot>
        <img v-if="selected.src" alt="language-flag" :src="selected.src" />
        <span>{{ selected.option }}</span>
        <base-icon
          icon="chevron-up"
          size="small"
          :class="['chevron', { 'chevron--open': open }]"
        />
      </slot>
    </div>
    <transition name="fade-down" appear>
      <div v-if="open" class="items">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="
            selected = option;
            open = false;
            $emit('get:input', option);
          "
        >
          <div :class="{ active: option === selected, container: true }">
            <img v-if="option.src" :src="option.src" />
            <span>{{ option.option }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

interface Option {
  option: string;
  src: string;
}

interface Props {
  options: Option[];
  default?: string;
  tabindex: number;
}

const { tabindex = 0, default: defaultProp, options } = defineProps<Props>();

const emit = defineEmits<{ (e: 'get:input', selected: Option): void }>();

const selected = ref<Option>(
  defaultProp || (options.length > 0 ? options[0] : null),
);
const open = ref(false);

onMounted(() => {
  // emit('get:input', selected.value);
});
</script>

<style lang="scss" scoped>
img {
  height: 2.5rem;
}

.active {
  background-color: var(--color-primary);
}

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 4;
}

.custom-select {
  position: relative;
  z-index: 5;
  text-align: left;
  outline: none;
  line-height: 47px;
}

.custom-select .selected {
  background-color: var(--color-black);
  border-radius: 6px;
  border: 1px solid $color-white;
  color: var(--color-white);
  padding-left: 1rem;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid $color-accent;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: '';
  top: 22px;
  right: 1rem;
  width: 0;
  height: 0;
  border: 5px solid transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid $color-accent;
  border-left: 1px solid $color-accent;
  border-bottom: 1px solid $color-accent;
  position: absolute;
  background-color: var(--color-black);
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 250ms ease-out;
}

.custom-select .items div {
  color: #fff;
  padding-left: 1rem;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: var(--color-primary-light);
}

.chevron {
  flex-shrink: 0;
  display: flex;
  transform-origin: center;
  transition: transform 250ms ease-out;
  transform: translateY(-10%);

  &--open {
    transform: rotate(180deg);
  }
}
</style>

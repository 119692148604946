<template>
  <base-selector
    class="language"
    :tabindex="0"
    :options="languages"
    @get:input="updateLanguage"
  />
</template>

<script lang="ts">
import BaseSelector from '@/components/BaseSelector.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseLanguageSelector',
  components: { BaseSelector },
  data() {
    return {
      languages: [
        {
          option: 'EN',
          src: 'https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png',
        },
        {
          option: 'ES',
          src: 'https://cdn.countryflags.com/thumbs/spain/flag-800.png',
        },
        {
          option: 'DE',
          src: 'https://cdn.countryflags.com/thumbs/austria/flag-800.png',
        },
      ],
    };
  },
  methods: {
    updateLanguage(value) {
      this.$i18n.locale = value.option.toLowerCase();
    },
  },
});
</script>

<style lang="scss" scoped>
.language {
  transform: scale(0.7);
  box-shadow: $shadow-medium;
}

:deep(.container) {
  padding-top: 0;
}
</style>
